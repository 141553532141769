.page__signup {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-top: 2em;
    
    form {
      background: white;
    }
  
    fieldset {
        border: none;
        margin: 0;
        padding: 1em;
        text-align: center;
        @include breakpoint($large) {
            display: grid;
            grid-template-columns: 1fr 3fr;
            gap: 1em;
            text-align: left;
        }
        place-items: center;
    }
  
    p.signup__title {
      font-size: 1em;
      font-weight: bold;
      @include breakpoint($medium) {
        margin-block-start: 0em;
      }
      margin-block-end: 0.25em;
    }
    p {
      font-size: 0.8em;
      margin-block-start: 0em;
      margin-block-end: 0.5em;
    }
    
    .input-medium {
      width: 250px;
      border: 1px solid #ccc;
      font-size: 0.9em;
      display: block;
      margin: auto;
      margin-bottom: 1em;
      text-align: center;
      @include breakpoint($large) {
        display: inline;
        text-align: left;
        margin-bottom: 0em;
      }
    }
  }